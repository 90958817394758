import React from "react";
import { Container } from "components/style/layoutStyle";
import { MobileNavTitle, PrevButtonWrapper } from "./style";
import Image from "components/image/image";
import { IC_PREV_ARROW } from "constants/media/icon";
import { navigate } from "gatsby-link";
import { AccentText } from "../../components/style/textStyle";

const MobileNav = ({ title, count }) => {
    const moveToPrev = () => {
        navigate(-1);
    };

    return (
        <Container height={"54px"} bgColor={"#f8f8f8"} justifycontent={"center"}>
            <PrevButtonWrapper onClick={() => moveToPrev()}>
                <Image filename={IC_PREV_ARROW} alt="mobile notice detail prev button arrow" />
            </PrevButtonWrapper>
            {count ? (
                <MobileNavTitle>
                    {title}&nbsp;<AccentText>{count}</AccentText>&nbsp;건
                </MobileNavTitle>
            ) : (
                <MobileNavTitle>{title}</MobileNavTitle>
            )}
        </Container>
    );
};

export default MobileNav;
