import React from "react";
import ResponsiveMedia from "utils/responsive_media";

import { Container, Box } from "components/style/layoutStyle";
import FaqTable from "../table/faqTable";
import NoticeTable from "../table/noticeTable";
import ResultCount from "./resultCount";
import ResultTableTopBar from "./resultTableTopBar";

const SearchResultMoreList = ({ data, variety }) => {
    const { isMobile } = ResponsiveMedia();

    return (
        <Container margin={isMobile === "true" ? "0 0 100px" : "0 0 140px"}>
            <Box flexDirection={"column"}>
                {!isMobile && (
                    <>
                        <ResultCount isList />
                        <ResultTableTopBar isList variety={variety} data={data} />
                    </>
                )}
                {variety === "faq" && <FaqTable />}
                {variety === "notice" && <NoticeTable />}
            </Box>
        </Container>
    );
};

export default SearchResultMoreList;
