import React, { useMemo } from "react";
import ResponsiveMedia from "utils/responsive_media";

import Seo from "components/seo";
import Top from "organisms/support/top";
import MobileNav from "organisms/support/mobileNav";
import SearchResultMoreList from "organisms/support/search/searchResultMoreList";
import { NOTICE_META, NOTICE_META_EN } from "../../../constants/data/meta_data";
import LayoutSupport from "../../../templates/layoutSupport";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

const SearchListPage = ({ location }) => {
    const { t, i18n } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    // 추후 수정
    const data = useMemo(() => {
        if (typeof location.state?.data === `undefined`) return null;
        return location.state.data;
    }, [location.state]);

    const variety = useMemo(() => {
        if (typeof location.state?.variety === `undefined`) return "faq";
        return location.state.variety;
    }, [location.state]);

    const seoData = useMemo(() => {
        if (i18n.language === "en") return NOTICE_META_EN;

        return NOTICE_META;
    }, [i18n.language]);

    return (
        <LayoutSupport>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            {isMobile === "true" ? <MobileNav title={variety === "faq" ? t("SUPPORT_SEARCH_RESULT_TEXT1") : t("SUPPORT_SEARCH_RESULT_TEXT2")} count={data.length} /> : <Top />}
            <SearchResultMoreList data={data} variety={variety === "faq" ? "faq" : "notice"} />
        </LayoutSupport>
    );
};

export default SearchListPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
